import Loader from '@sportnet/ui/Loader';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { loadAcl } from '../Authorization/actions';
import { aclSelector } from '../Authorization/selectors';
import DomainSettings from '../DomainSettings';
import Questions from '../Questions';
import QuestionsList from '../QuestionsList';
import QuestionsPrint from '../QuestionsPrint';
import Seminars from '../Seminars';
import SeminarsList from '../SeminarsList';
import SeminarUsers from '../SeminarUsers';
import TestByUsersList from '../TestByUsersList';
import Tests from '../Tests';
import TestsList from '../TestsList';
import TestTemplateusers from '../TestTemplateUsers';
import TestUsers from '../TestUsers';
import TestUsersList from '../TestUsersList';
import TestUsersPrint from '../TestUsersPrint';
import UserTests from '../User/Tests';
import { loadAppSpaceUsers } from './actions';
import { useAuth } from '@sportnet/auth-react';
import AppContext from '../../context/AppContext';
import {
  setApps,
  setAppSpaces,
  setActiveAppSpaceId,
  setApplicationInfo,
  setAuthUser,
} from '../../containers/Authorization/actions';
import CoreApi from '../../SportnetApi';
import { getApiError } from '../../utils';
import { setActiveAppSpace } from '../../containers/Authorization/actions';

const INITIAL_APP_STATE = {
  isLoading: false,
  // objekt appspace s ktorym pracujem
  activeAppSpace: undefined,
  // vsetky appspaces ku ktorym ma uzivatel pristup
  appSpaces: undefined,
  // info o aplikacii
  appInfo: undefined,
  // appky, ku ktorym ma uzivatel pristup
  apps: undefined,
  // id appspace s ktorym pracujem
  activeAppSpaceId: undefined,
  //
  accessToken: undefined,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'START_AUTH': {
      return {
        ...INITIAL_APP_STATE,
        isLoading: true,
      };
    }
    case 'DONE': {
      if (action.payload) {
        const {
          apps,
          appSpaces,
          activeAppSpaceId,
          appInfo,
          activeAppSpace,
          accessToken,
        } = action.payload;
        return {
          ...state,
          apps,
          appSpaces,
          activeAppSpaceId,
          appInfo,
          isLoading: false,
          activeAppSpace,
          accessToken,
        };
      }
      return state;
    }
    case 'SET_ACCESS_TOKEN': {
      if (action.payload && action.payload.accessToken) {
        return {
          ...state,
          accessToken: action.payload.accessToken,
        };
      }
      return state;
    }

    case 'ERROR': {
      return {
        ...INITIAL_APP_STATE,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

const Administration = ({
  acl,
  setActiveAppSpace,
  loadAcl,
  loadAppSpaceUsers,
  setActiveAppSpaceId,
  setAppSpaces,
  setApps,
  setApplicationInfo,
}) => {
  const [state, dispatch] = React.useReducer(reducer, INITIAL_APP_STATE);
  const [notificationCount, setNotificationCount] = React.useState(-1);

  const { ppo: appSpace, authUser, accessToken } = useAuth();

  const timerRef = React.useRef();

  React.useEffect(() => {
    if (appSpace) {
      loadAcl(appSpace);
      loadAppSpaceUsers(appSpace);
    }
  }, [appSpace, loadAcl, loadAppSpaceUsers]);

  React.useEffect(() => {
    if (!appSpace) {
      return;
    }
    (async () => {
      try {
        // proces "vsetko alebo nic" - aks a nepodari ziskat aplikaciu na zaklade ppo alebo
        // appSpace na zaklade ppo z URL, koncime chybou.
        const response = await CoreApi.meAppSpaces({ expandApp: true });
        const apps = (response?.apps || []).map((a) => {
          const appSpaceIds = (a.appspaces || []).map((as) => as.app_space);
          return { ...a.app, appSpaceIds };
        });
        setApps(apps);

        const appObj = (response.apps || []).find(
          (a) => a.app_id === process.env.REACT_APP_APP_ID,
        );

        const appSpaces = appObj.appspaces || [];
        setAppSpaces(appSpaces);

        setActiveAppSpaceId(appSpace);

        const appInfo = await CoreApi.getPublicApp(
          process.env.REACT_APP_APP_ID,
        );
        setApplicationInfo(appInfo);

        const activeAppSpace = await CoreApi.organizationPPOProfile(appSpace);
        console.log('activeAppSpace', activeAppSpace);
        setActiveAppSpace({
          org_profile: activeAppSpace,
          app_space: activeAppSpace._id,
        });

        // vsetko v store na jeden render
        dispatch({
          type: 'DONE',
          payload: {
            apps,
            appSpaces,
            activeAppSpaceId: activeAppSpace.app_space,
            appInfo,
            activeAppSpace: {
              org_profile: activeAppSpace,
              app_space: activeAppSpace._id,
            },
          },
        });
      } catch (e) {
        const err = getApiError(e);
        alert(
          `Chyba autorizácie: ${err.details.statusCode} ${err.details.name} ${err.message}`,
        );
      }
    })();
  }, [
    appSpace,
    setActiveAppSpace,
    setActiveAppSpaceId,
    setApplicationInfo,
    setApps,
    setAppSpaces,
  ]);

  React.useEffect(() => {
    setAuthUser(
      {
        _id: authUser.sportnetId,
        name: authUser.name,
        surname: authUser.surname,
        grant: authUser.appSpace?.grant,
        admin: authUser.appSpace?.admin,
      },
      accessToken,
    );
    dispatch({
      type: 'SET_ACCESS_TOKEN',
      payload: {
        accessToken,
      },
    });
  }, [authUser, accessToken]);

  React.useEffect(() => {
    if (appSpace) {
      dispatch({ type: 'START_AUTH' });
    }
  }, [appSpace]);

  React.useEffect(() => {
    if (!accessToken) {
      return;
    }

    const fetchNotificationCount = async () => {
      try {
        const { count } = await CoreApi.meUnreadMessagesCount();
        setNotificationCount(count ?? 0);
        if (timerRef.current) {
          window.clearInterval(timerRef.current);
        }
        timerRef.current = window.setTimeout(fetchNotificationCount, 300000);
      } catch (e) {
        setNotificationCount(-1);
      }
    };

    fetchNotificationCount();

    return () => {
      if (timerRef.current) {
        window.clearInterval(timerRef.current);
      }
    };
  }, [accessToken]);

  const appContextValue = React.useMemo(() => {
    return {
      activeAppSpace: state.activeAppSpace,
      appSpaces: state.appSpaces,
      appInfo: state.appInfo,
      apps: state.apps,
      activeAppSpaceId: state.activeAppSpaceId,
      notificationCount,
    };
  }, [state, notificationCount]);

  if (state.isLoading || !acl || !state.activeAppSpace || !state.appInfo) {
    return (
      <LoaderWrapper>
        <Loader size="xl" />
      </LoaderWrapper>
    );
  }

  return (
    <AppContext.Provider value={appContextValue}>
      <Switch>
        <Route
          path="/userspace/:userspace_id/question/:question_id?"
          component={Questions}
        />
        <Route
          exact
          path="/userspace/:userspace_id/questions"
          component={QuestionsList}
        />
        <Route
          exact
          path="/userspace/:userspace_id/questions-print"
          component={QuestionsPrint}
        />
        <Route
          exact
          path="/userspace/:userspace_id/template/:test_id?"
          component={Tests}
        />
        <Route
          exact
          path="/userspace/:userspace_id/seminar/:seminar_id?"
          component={Seminars}
        />
        <Route
          exact
          path="/userspace/:userspace_id/seminar/:seminar_id/users"
          component={SeminarUsers}
        />
        <Route
          exact
          path="/userspace/:userspace_id/template/:template_id/test/:test_id/print"
          component={TestUsersPrint}
        />
        <Route
          exact
          path="/userspace/:userspace_id/template/:template_id/test/:test_id"
          component={TestUsers}
        />
        <Route
          exact
          path="/userspace/:userspace_id/template/:test_id/tests"
          component={TestUsersList}
        />
        <Route
          exact
          path="/userspace/:userspace_id/template/:test_id/users"
          component={TestByUsersList}
        />
        <Route
          exact
          path="/userspace/:userspace_id/template/:test_id/templateusers"
          component={TestTemplateusers}
        />
        <Route
          exact
          path="/userspace/:userspace_id/templates"
          component={TestsList}
        />
        <Route
          exact
          path="/userspace/:userspace_id/seminars"
          component={SeminarsList}
        />
        <Route
          exact
          path="/userspace/:userspace_id/settings/domain"
          component={DomainSettings}
        />
        <Route
          exact
          path="/userspace/:userspace_id/users/:sportnetId/tests"
          component={UserTests}
        />
        <Redirect
          from="/userspace/:userspace_id/:path?"
          to="/userspace/:userspace_id/questions"
        />
      </Switch>
    </AppContext.Provider>
  );
};

Administration.propTypes = {
  acl: PropTypes.shape({}),
};

Administration.defaultProps = {
  acl: null,
};

const mapStateToProps = (state) => {
  return {
    acl: aclSelector(state),
  };
};

const mapDispatchToProps = {
  loadAcl,
  loadAppSpaceUsers,
  setActiveAppSpace,
  setAppSpaces,
  setApps,
  setApplicationInfo,
  setActiveAppSpaceId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Administration);
