import React from 'react';
import { Route, useHistory, Redirect } from 'react-router-dom';
import Authorization from '../Authorization';
import AuthProvider from '@sportnet/auth-react/AuthProvider';
import PpoSelector from '@sportnet/auth-react/PpoSelector';
import config from '../../config';

const App = () => {
  const history = useHistory();

  return (
    <>
      <AuthProvider app={config.APP_ID}>
        <Route exact path="/">
          <PpoSelector
            onSelectAppspace={(appSpace) => {
              history.push(`/userspace/${appSpace.ppo}/questions`);
            }}
          />
        </Route>
        <Route path={'/_auth'} />
      </AuthProvider>
      <Route path="/userspace/:appspace/:path?" component={Authorization} />
      <Route exact path="/userspace">
        <Redirect to="/" />
      </Route>
    </>
  );
};

export default App;
