import TheLayout from '@sportnet/ui/TheLayout';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getProp } from '@sportnet/utilities';
import styled from 'styled-components';
import { aclSelector } from '../../containers/Authorization/selectors';
import AppContext from '../../context/AppContext';
import { __ } from '../../utils';
import { useAuth } from '@sportnet/auth-react';

const TopFixed = styled.div`
  background-color: #fff;
`;

const Layout = ({ acl, topFixed, bottomFixed, children }) => {
  const [categories, setCategories] = React.useState();

  const { ppo: appSpace, authUser, accessToken, notificationCount } = useAuth();

  const { appInfo, activeAppSpace, appSpaces, apps } =
    React.useContext(AppContext);

  // nacitanie poloziek menu podla ACL
  React.useEffect(() => {
    const getCategories = (appSpace, _acl) => {
      if (appSpace && _acl) {
        const categories = [
          {
            _id: `/userspace/${appSpace}/questions`,
            url: `/userspace/${appSpace}/questions`,
            label: __('Otázky'),
            acl: ['questionList'],
            icon: 'question',
          },
          {
            _id: `/userspace/${appSpace}/templates`,
            url: `/userspace/${appSpace}/templates`,
            label: __('Šablóny a testy'),
            acl: ['templateList'],
            icon: 'test',
          },
          {
            _id: `/userspace/${appSpace}/seminars`,
            url: `/userspace/${appSpace}/seminars`,
            label: __('Semináre'),
            acl: ['seminarsList'],
            icon: 'write',
          },
          {
            _id: `/userspace/${appSpace}/settings/domain`,
            url: `/userspace/${appSpace}/settings/domain`,
            label: __('Nastavenie domény'),
            acl: ['adminEditSettings'],
            icon: 'settings',
          },
        ];
        if (Object.keys(_acl).length > 0) {
          return categories.reduce((acc, category) => {
            if (category.acl) {
              // eslint-disable-next-line
              for (const rule of category.acl) {
                if (acl[rule]) {
                  acc.push(category);
                  break;
                }
              }
              return acc;
            }
            acc.push(category);
            return acc;
          }, []);
        }
      }
      return [];
    };
    setCategories(getCategories(appSpace, acl));
  }, [acl, appSpace]);

  const theLayoutUserData = React.useMemo(() => {
    const data = {
      userPictureSrc: undefined,
      userName: undefined,
    };
    if (authUser) {
      data.userPictureSrc = authUser.photoIsPublic
        ? authUser.photoUrl
        : undefined;
      data.userName = authUser.appSpace?.displayName;
    }
    return data;
  }, [authUser]);

  if (!appSpace || !acl || !activeAppSpace) {
    return <>Authorizing...</>;
  }

  return (
    <TheLayout
      grant={authUser.appSpace?.grant}
      admin={authUser.appSpace?.admin}
      navigation={categories}
      userName={theLayoutUserData.userName}
      notificationCount={notificationCount}
      userPictureSrc={theLayoutUserData.userPictureSrc}
      top={<TopFixed>{topFixed}</TopFixed>}
      bottom={<div>{bottomFixed}</div>}
      onLogout={() => {
        this.context.logout();
      }}
      app={appInfo}
      appSpace={activeAppSpace?.org_profile}
      appSpaces={appSpaces?.map((as) => ({
        _id: as.app_space,
        name: as.org_profile?.name || '',
        organization_name: as.org_profile?.organization_name || '',
        logo_public_url: as.org_profile?.logo_public_url,
        organization_id: as.org_profile?._id || '',
      }))}
      accessToken={accessToken}
      apps={apps}
      renderNavigationLink={({ item, linkProps }) => (
        <NavLink exact to={item.url || ''} {...linkProps}>
          {linkProps.children}
        </NavLink>
      )}
      userSubName={getProp(activeAppSpace, ['role'], '')}
    >
      {children}
    </TheLayout>
  );
};

Layout.propTypes = {
  topFixed: PropTypes.node,
  bottomFixed: PropTypes.node,
  children: PropTypes.node.isRequired,
  acl: PropTypes.shape({
    tagList: PropTypes.bool,
  }).isRequired,
};

Layout.defaultProps = {
  topFixed: null,
  bottomFixed: null,
};

Layout.contextTypes = {
  logout: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    acl: aclSelector(state),
  };
};

export default connect(mapStateToProps)(Layout);
