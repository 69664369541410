/* eslint-disable */
import 'isomorphic-fetch';

class SportnetApi {
  constructor() {
    this.baseUrl = null;
    this.token = null;
    this.headers = [];
  }

  serializeQueryParams(parameters) {
    return Object.keys(parameters)
      .reduce((acc, p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  paramsToObject(params) {
    let query = params.substr(1);
    let result = {};
    query.split('&').forEach(function (part) {
      let item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName,
      ) {
        const parameter = parameters.$queryParameters[parameterName];
        queryParameters[parameterName] = parameter;
      });
    }
    return queryParameters;
  }

  transformParameter(value, transformOperation = null) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  /**
   * HTTP Request
   * @method
   * @param {string} method - http method
   * @param {string} url - url to do request
   * @param {object} body - body parameters / object
   * @param {object} headers - header parameters
   * @param {object} queryParameters - querystring parameters
   */
  request(
    method,
    url,
    body,
    headers,
    queryParameters,
    form,
    checkFor401 = true,
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    // ugly hack, we need to delete Content-Type header with multipart/form-data
    // that way, browser will calculate form specific headers on it's own
    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (body && !Object.keys(body).length) {
      body = undefined;
    } else {
      body = JSON.stringify(body);
    }

    if (form && Object.keys(form).length) {
      body = new FormData();
      for (let k in form) {
        body.append(k, form[k]);
      }
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    return fetch(urlWithParams, {
      method,
      headers,
      body,
    })
      .then((response) => {
        if (checkFor401) {
          if (response.status === 401) {
            if (typeof this._onResponseUnauthorized === 'function') {
              this._onResponseUnauthorized();
            } else {
              let error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          }
        }

        if (response.status === 204) return response;

        if (response.ok) {
          const responseContentType =
            (response.headers && response.headers.get('Content-Type')) || '';
          if (responseContentType.includes('application/json')) {
            return response.json();
          }
          return response;
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch((error) => {
        return error.response.json().then((error_details) => {
          error.details = error_details;
          throw error;
        });
      });
  }

  /**
   * Set base url
   * @method
   * @param {string} baseUrl
   */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  /**
   * Set Headers
   * @method
   * @param headers - array of headers
   */
  setHeaders(headers) {
    this.headers = headers;
  }

  set onResponseUnauthorized(callback) {
    this._onResponseUnauthorized = callback;
  }

  /**
   * Redirects a user to a given url
   * @method
   * @param {string} url
   */
  redirect(url) {
    window.location = url;
  }

  /**
   * Set Token
   * @method
   * @param {string} token - token's value
   */
  setToken(token) {
    this.token = token;
  }

  /**
   * Set Auth headers
   * @method
   * @param {object} headerParams - headers object
   */
  appendAuthHeaders(headerParams) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  /**
   * Update or Create Push token. Push token is assigned to sportnet user, app_id and appSpace (from authorization).
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  manageUserPushToken(parameters = {}) {
    let path = '/me/push-tokens';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.ids -
   * @param {array} parameters.sectors -
   * @param {array} parameters.type -
   * @param {string} parameters.q - Regex search in _id and name
   */
  organizationPPOProfiles(parameters = {}) {
    let path = '/ppo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    if (typeof parameters['sectors'] !== 'undefined') {
      queryParameters['sectors'] = parameters['sectors'];
    }

    queryParameters['sectors'] = this.transformParameter(
      queryParameters['sectors'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  organizationPPOProfile(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.q -
   * @param {array} parameters.ppoType -
   * @param {string} parameters.relationStatus -
   * @param {array} parameters.sports - Match only PPOs with one of speficied sports
   * @param {array} parameters.with - Add elements to projection
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} appSpace - App space (PPO _id)
   */
  organizationPPOListRelatedPPOs(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/related-ppos';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['ppoType'] !== 'undefined') {
      queryParameters['ppoType'] = parameters['ppoType'];
    }

    queryParameters['ppoType'] = this.transformParameter(
      queryParameters['ppoType'],
    );

    if (typeof parameters['relationStatus'] !== 'undefined') {
      queryParameters['relationStatus'] = parameters['relationStatus'];
    }

    queryParameters['relationStatus'] = this.transformParameter(
      queryParameters['relationStatus'],
    );

    if (typeof parameters['sports'] !== 'undefined') {
      queryParameters['sports'] = parameters['sports'];
    }

    queryParameters['sports'] = this.transformParameter(
      queryParameters['sports'],
    );

    if (typeof parameters['with'] !== 'undefined') {
      queryParameters['with'] = parameters['with'];
    }

    queryParameters['with'] = this.transformParameter(queryParameters['with']);

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} ppo - PPO _id
   */
  organizationRelatedPPO(appSpace, ppo, parameters = {}) {
    let path = '/ppo/{appSpace}/related-ppos/{ppo}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{ppo}', ppo);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.ppoType -
   * @param {string} appSpace - App space (PPO _id)
   */
  getPPORelationsStructure(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/relations-structure';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['ppoType'] !== 'undefined') {
      queryParameters['ppoType'] = parameters['ppoType'];
    }

    queryParameters['ppoType'] = this.transformParameter(
      queryParameters['ppoType'],
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  getPPOLicensesIssuer(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/licenses-issuer';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  organizationPPOGDPRBody(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/gdpr';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  organizationPPOContactInfo(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/contact-info';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  organizationPPOContactPersons(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/contact-persons';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  organizationPPOTheme(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/theme';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.relatedTo -
   * @param {string} parameters.q -
   * @param {string} parameters.autocomplete - Use autocomplete search and return highlights
   * @param {string} parameters.affiliationDateFrom -
   * @param {string} parameters.affiliationDateTo -
   * @param {string} parameters.birthdateFrom -
   * @param {string} parameters.birthdateTo -
   * @param {string} parameters.affiliationCategory - Either sport_orgs or sport_expert_orgs
   * @param {string} parameters.competenceType - You need to define affiliationCategory for this filter to work
   * @param {boolean} parameters.affiliationActive -
   * @param {boolean} parameters.membershipActive -
   * @param {boolean} parameters.bioActive -
   * @param {string} parameters.sportSector -
   * @param {array} parameters.ids -
   * @param {string} parameters.regnr -
   * @param {string} parameters.sorter - Define sort order
   */
  organizationPPOUsers(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['relatedTo'] !== 'undefined') {
      queryParameters['relatedTo'] = parameters['relatedTo'];
    }

    queryParameters['relatedTo'] = this.transformParameter(
      queryParameters['relatedTo'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['autocomplete'] !== 'undefined') {
      queryParameters['autocomplete'] = parameters['autocomplete'];
    }

    queryParameters['autocomplete'] = this.transformParameter(
      queryParameters['autocomplete'],
    );

    if (typeof parameters['affiliationDateFrom'] !== 'undefined') {
      queryParameters['affiliationDateFrom'] =
        parameters['affiliationDateFrom'];
    }

    queryParameters['affiliationDateFrom'] = this.transformParameter(
      queryParameters['affiliationDateFrom'],
    );

    if (typeof parameters['affiliationDateTo'] !== 'undefined') {
      queryParameters['affiliationDateTo'] = parameters['affiliationDateTo'];
    }

    queryParameters['affiliationDateTo'] = this.transformParameter(
      queryParameters['affiliationDateTo'],
    );

    if (typeof parameters['birthdateFrom'] !== 'undefined') {
      queryParameters['birthdateFrom'] = parameters['birthdateFrom'];
    }

    queryParameters['birthdateFrom'] = this.transformParameter(
      queryParameters['birthdateFrom'],
    );

    if (typeof parameters['birthdateTo'] !== 'undefined') {
      queryParameters['birthdateTo'] = parameters['birthdateTo'];
    }

    queryParameters['birthdateTo'] = this.transformParameter(
      queryParameters['birthdateTo'],
    );

    if (typeof parameters['affiliationCategory'] !== 'undefined') {
      queryParameters['affiliationCategory'] =
        parameters['affiliationCategory'];
    }

    queryParameters['affiliationCategory'] = this.transformParameter(
      queryParameters['affiliationCategory'],
    );

    if (typeof parameters['competenceType'] !== 'undefined') {
      queryParameters['competenceType'] = parameters['competenceType'];
    }

    queryParameters['competenceType'] = this.transformParameter(
      queryParameters['competenceType'],
    );

    queryParameters['affiliationActive'] = true;

    if (typeof parameters['affiliationActive'] !== 'undefined') {
      queryParameters['affiliationActive'] = parameters['affiliationActive'];
    }

    queryParameters['affiliationActive'] = this.transformParameter(
      queryParameters['affiliationActive'],
    );

    queryParameters['membershipActive'] = false;

    if (typeof parameters['membershipActive'] !== 'undefined') {
      queryParameters['membershipActive'] = parameters['membershipActive'];
    }

    queryParameters['membershipActive'] = this.transformParameter(
      queryParameters['membershipActive'],
    );

    queryParameters['bioActive'] = false;

    if (typeof parameters['bioActive'] !== 'undefined') {
      queryParameters['bioActive'] = parameters['bioActive'];
    }

    queryParameters['bioActive'] = this.transformParameter(
      queryParameters['bioActive'],
    );

    if (typeof parameters['sportSector'] !== 'undefined') {
      queryParameters['sportSector'] = parameters['sportSector'];
    }

    queryParameters['sportSector'] = this.transformParameter(
      queryParameters['sportSector'],
    );

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    if (typeof parameters['regnr'] !== 'undefined') {
      queryParameters['regnr'] = parameters['regnr'];
    }

    queryParameters['regnr'] = this.transformParameter(
      queryParameters['regnr'],
    );

    queryParameters['sorter'] = 'person';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {boolean} parameters.withRelatedAffiliations -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userId - User Id
   */
  organizationPPOUser(appSpace, userId, parameters = {}) {
    let path = '/ppo/{appSpace}/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['withRelatedAffiliations'] = false;

    if (typeof parameters['withRelatedAffiliations'] !== 'undefined') {
      queryParameters['withRelatedAffiliations'] =
        parameters['withRelatedAffiliations'];
    }

    queryParameters['withRelatedAffiliations'] = this.transformParameter(
      queryParameters['withRelatedAffiliations'],
    );

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Can be used as image src
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userId - User Id
   * @param {string} token - Formal photo token
   */
  getFormalImage(appSpace, userId, token, parameters = {}) {
    let path = '/ppo/{appSpace}/users/{userId}/formal-photo/{token}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Accept', 'image/png');
    headers.append('Accept', 'image/jpeg');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userId}', userId);

    path = path.replace('{token}', token);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  ppoOrganizationBankAccounts(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/organization/bank-accounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.type - filter addresses by type
   * @param {string} appSpace - App space (PPO _id)
   */
  ppoOrganizationAddresses(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/organization/addresses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   */
  organizationInvoiceProfile(orgId, parameters = {}) {
    let path = '/organizations/{orgId}/invoice-profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  ppoInvoiceProfile(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/invoice-profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {string} appSpace - App space (PPO _id)
   */
  crmGetCourierTemplates(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/courier/templates';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   */
  crmSendMessages(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/courier/templates';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} courierTemplateId - Courier Template Id
   */
  crmGetCourierTemplateById(appSpace, courierTemplateId, parameters = {}) {
    let path = '/crm/{appSpace}/courier/templates/{courierTemplateId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{courierTemplateId}', courierTemplateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} courierTemplateId - Courier Template Id
   */
  crmGetCourierMessagesByTemplateId(
    appSpace,
    courierTemplateId,
    parameters = {},
  ) {
    let path = '/crm/{appSpace}/courier/templates/{courierTemplateId}/messages';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{courierTemplateId}', courierTemplateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  getPublicAppSpaceGroups(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/user-groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  crmGetAppSpaceGroups(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/user-groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {array} parameters.userGroupIds -
   * @param {string} parameters.sorter -
   */
  getUsersByAppSpaceGroup(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/user-groups/users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['userGroupIds'] !== 'undefined') {
      queryParameters['userGroupIds'] = parameters['userGroupIds'];
    }

    queryParameters['userGroupIds'] = this.transformParameter(
      queryParameters['userGroupIds'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.q -
   * @param {string} parameters.businessId - Search organizations by ICO
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {integer} parameters.limit - Limit for paginated results
   */
  getOrganizations(parameters = {}) {
    let path = '/organizations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['businessId'] !== 'undefined') {
      queryParameters['businessId'] = parameters['businessId'];
    }

    queryParameters['businessId'] = this.transformParameter(
      queryParameters['businessId'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   */
  getOrganizationProfiles(orgId, parameters = {}) {
    let path = '/organizations/{orgId}/profiles';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} invitationId - Invitation Id
   */
  processAppgrantInvitationResponse(invitationId, parameters = {}) {
    let path = '/appgrant-invitation-callback/{invitationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{invitationId}', invitationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} invitationId - Invitation Id
   */
  processContactPersonInvitationResponse(invitationId, parameters = {}) {
    let path = '/crm-contact-person-invitation-callback/{invitationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{invitationId}', invitationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} invitationId - Invitation Id
   */
  processPersonRelationInvitationResponse(invitationId, parameters = {}) {
    let path = '/person-relation-invitation-callback/{invitationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{invitationId}', invitationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Invite email for `appId` in `appSpace`. Use this method for update user permissions via invitation also.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appId - App ID
   * @param {string} appSpace - App space (PPO _id)
   */
  appgrantInviteUser(appId, appSpace, parameters = {}) {
    let path = '/appgrant-invitation/{appId}/{appSpace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get grant roles for `appId` in `appSpace`
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appId - App ID
   * @param {string} appSpace - App space (PPO _id)
   */
  appgrantGetRoles(appId, appSpace, parameters = {}) {
    let path = '/appgrant/{appId}/{appSpace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Set grant roles for `appId` in `appSpace`. Use this method for update user permissions also.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appId - App ID
   * @param {string} appSpace - App space (PPO _id)
   */
  appgrantRegisterUser(appId, appSpace, parameters = {}) {
    let path = '/appgrant/{appId}/{appSpace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete grant role for `appId` in `appSpace` for `userId`
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appId - App ID
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userId - User Id
   */
  appgrantDeleteRoleForUser(appId, appSpace, userId, parameters = {}) {
    let path = '/appgrant/{appId}/{appSpace}/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Helper method to get user public profile by userId
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appId - App ID
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userId - User Id
   */
  appgrantGetProfileForUser(appId, appSpace, userId, parameters = {}) {
    let path = '/appgrant/{appId}/{appSpace}/{userId}/profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get public (primary) apps list
   * @method
   * @param {object} parameters - method options and parameters
   * @param {boolean} parameters.graph - Doplni objekty podaplikacii
   */
  getPublicApps(parameters = {}) {
    let path = '/apps';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['graph'] = false;

    if (typeof parameters['graph'] !== 'undefined') {
      queryParameters['graph'] = parameters['graph'];
    }

    queryParameters['graph'] = this.transformParameter(
      queryParameters['graph'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get public app detail
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appId - App ID
   */
  getPublicApp(appId, parameters = {}) {
    let path = '/apps/{appId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.externalProfile - Include external profile id for external service (e.g. 'sme')
   * @param {boolean} parameters.withGroups - Include groups for PPO, which the accessToken is registered
   */
  me(parameters = {}) {
    let path = '/me';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['externalProfile'] !== 'undefined') {
      queryParameters['externalProfile'] = parameters['externalProfile'];
    }

    queryParameters['externalProfile'] = this.transformParameter(
      queryParameters['externalProfile'],
    );

    queryParameters['withGroups'] = false;

    if (typeof parameters['withGroups'] !== 'undefined') {
      queryParameters['withGroups'] = parameters['withGroups'];
    }

    queryParameters['withGroups'] = this.transformParameter(
      queryParameters['withGroups'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {string} parameters.appId - AppIds to filter
   * @param {array} parameters.appItemId - ItemIds to filter. Makes sence to filter together with appId
   * @param {array} parameters.appFavType - Fav types to filter. Makes sence to filter together with appId
   * @param {array} parameters.appSpace - Filter favs by appSpace
   */
  meFavorites(parameters = {}) {
    let path = '/me/favorites';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['appId'] !== 'undefined') {
      queryParameters['appId'] = parameters['appId'];
    }

    queryParameters['appId'] = this.transformParameter(
      queryParameters['appId'],
    );

    if (typeof parameters['appItemId'] !== 'undefined') {
      queryParameters['appItemId'] = parameters['appItemId'];
    }

    queryParameters['appItemId'] = this.transformParameter(
      queryParameters['appItemId'],
    );

    if (typeof parameters['appFavType'] !== 'undefined') {
      queryParameters['appFavType'] = parameters['appFavType'];
    }

    queryParameters['appFavType'] = this.transformParameter(
      queryParameters['appFavType'],
    );

    if (typeof parameters['appSpace'] !== 'undefined') {
      queryParameters['appSpace'] = parameters['appSpace'];
    }

    queryParameters['appSpace'] = this.transformParameter(
      queryParameters['appSpace'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  meFavoritesCreateItem(parameters = {}) {
    let path = '/me/favorites';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Reset (remove) all favorite items created by appId / appSpace - based on token.
   * @method
   * @param {object} parameters - method options and parameters
   */
  meFavoritesResetForApp(parameters = {}) {
    let path = '/me/favorites';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * System checks the appId of the item, unless token belongs to privileged app (my.sportnet.online for example).
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} favId - Fav ID to remove
   */
  meFavoritesRemoveItem(favId, parameters = {}) {
    let path = '/me/favorites/{favId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{favId}', favId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {integer} parameters.limit - Limit for paginated results
   */
  meInvitations(parameters = {}) {
    let path = '/me/invitations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meRelations(parameters = {}) {
    let path = '/me/relations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create new relation invitation
   */
  meNewRelationInvitation(parameters = {}) {
    let path = '/me/relations-invitation';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} relatedId -
   */
  meRelationTakeover(relatedId, parameters = {}) {
    let path = '/me/relations/{relatedId}/takeover';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{relatedId}', relatedId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {string} parameters.q -
   * @param {boolean} parameters.read -
   * @param {boolean} parameters.important -
   */
  meMessages(parameters = {}) {
    let path = '/me/messages';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['read'] !== 'undefined') {
      queryParameters['read'] = parameters['read'];
    }

    queryParameters['read'] = this.transformParameter(queryParameters['read']);

    if (typeof parameters['important'] !== 'undefined') {
      queryParameters['important'] = parameters['important'];
    }

    queryParameters['important'] = this.transformParameter(
      queryParameters['important'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meMarkAllMessagesAsRead(parameters = {}) {
    let path = '/me/messages';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meUnreadMessagesCount(parameters = {}) {
    let path = '/me/messages-unread-count';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} courierMessageIds -
   */
  meMessageById(courierMessageIds, parameters = {}) {
    let path = '/me/messages/{courierMessageIds}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{courierMessageIds}', courierMessageIds);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {array} courierMessageIds -
   */
  meMessageStatusChange(courierMessageIds, parameters = {}) {
    let path = '/me/messages/{courierMessageIds}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{courierMessageIds}', courierMessageIds);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meUserGroups(parameters = {}) {
    let path = '/me/groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meRequests(parameters = {}) {
    let path = '/me/requests';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meInvoices(parameters = {}) {
    let path = '/me/invoices';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre ziskanie download ID pdf faktury
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} invoiceId -
   */
  meInvoicePdf(invoiceId, parameters = {}) {
    let path = '/me/invoices/{invoiceId}/pdf';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meAwards(parameters = {}) {
    let path = '/me/awards';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meAwardsGet(itemId, parameters = {}) {
    let path = '/me/awards/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meMembership(parameters = {}) {
    let path = '/me/membership';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meMembershipGet(itemId, parameters = {}) {
    let path = '/me/membership/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meRegnrs(parameters = {}) {
    let path = '/me/regnrs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meBios(parameters = {}) {
    let path = '/me/bios';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meProfiles(parameters = {}) {
    let path = '/me/profiles';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meProfilesIssf(parameters = {}) {
    let path = '/me/profiles/issf';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.code - Code from ISSF OAUTH response
   * @param {string} parameters.redirectUri - Redirect URI used in ISSF request
   */
  meProfilesIssfConnect(parameters = {}) {
    let path = '/me/profiles/issf/connect';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['code'] !== 'undefined') {
      queryParameters['code'] = parameters['code'];
    }

    queryParameters['code'] = this.transformParameter(queryParameters['code']);

    if (typeof parameters['code'] === 'undefined') {
      throw Error('Missing required parameter: code');
    }

    if (typeof parameters['redirectUri'] !== 'undefined') {
      queryParameters['redirect_uri'] = parameters['redirectUri'];
    }

    queryParameters['redirect_uri'] = this.transformParameter(
      queryParameters['redirect_uri'],
    );

    if (typeof parameters['redirectUri'] === 'undefined') {
      throw Error('Missing required parameter: redirectUri');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meProfilesIssfToken(parameters = {}) {
    let path = '/me/profiles/issf/token';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  meProfilesFacebookConnect(parameters = {}) {
    let path = '/me/profiles/facebook/connect';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meProfilesFacebookDisconnect(parameters = {}) {
    let path = '/me/profiles/facebook';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meProfilesGoogleDisconnect(parameters = {}) {
    let path = '/me/profiles/google';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meProfilesAppleDisconnect(parameters = {}) {
    let path = '/me/profiles/apple';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meProfile(parameters = {}) {
    let path = '/me/profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update basic user profile data
   */
  meProfileUpdate(parameters = {}) {
    let path = '/me/profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.type - filter addresses by type
   */
  meAddresses(parameters = {}) {
    let path = '/me/addresses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Address
   */
  meAddressesCreate(parameters = {}) {
    let path = '/me/addresses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meAddressesGet(itemId, parameters = {}) {
    let path = '/me/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Address
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meAddressesUpdate(itemId, parameters = {}) {
    let path = '/me/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meAddressesDelete(itemId, parameters = {}) {
    let path = '/me/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meBankAccounts(parameters = {}) {
    let path = '/me/bank-accounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create Bank Account
   */
  meBankAccountsCreate(parameters = {}) {
    let path = '/me/bank-accounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meBankAccountsGet(itemId, parameters = {}) {
    let path = '/me/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Bank Account
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meBankAccountsUpdate(itemId, parameters = {}) {
    let path = '/me/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meBankAccountsDelete(itemId, parameters = {}) {
    let path = '/me/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meCustomFields(parameters = {}) {
    let path = '/me/custom';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create Custom Field
   */
  meCustomFieldsCreate(parameters = {}) {
    let path = '/me/custom';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meCustomFieldsGet(itemId, parameters = {}) {
    let path = '/me/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Custom Field
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meCustomFieldsUpdate(itemId, parameters = {}) {
    let path = '/me/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meCustomFieldsDelete(itemId, parameters = {}) {
    let path = '/me/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
     * Store various app data for user. Data is scoped for user, appId and appSpace. appId and appSpace is read from access token.
Data is in schema of type object (key-value, where value can be any type).

     * @method
     * @param {object} parameters - method options and parameters
     */
  meAppDataGet(parameters = {}) {
    let path = '/me/appdata';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Custom Field
   */
  meAppDataReplace(parameters = {}) {
    let path = '/me/appdata';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Custom Field
   */
  meAppDataUpdate(parameters = {}) {
    let path = '/me/appdata';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PATCH',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meSportOrgs(parameters = {}) {
    let path = '/me/sport-orgs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meSportOrgItem(itemId, parameters = {}) {
    let path = '/me/sport-orgs/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meSportExpertOrgs(parameters = {}) {
    let path = '/me/sport-expert-orgs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meSportExpertOrgItem(itemId, parameters = {}) {
    let path = '/me/sport-expert-orgs/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meLicenses(parameters = {}) {
    let path = '/me/licenses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create User License
   */
  meLicensesCreate(parameters = {}) {
    let path = '/me/licenses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
     * List of all user roles, in all appspaces, divided by app_id

     * @method
     * @param {object} parameters - method options and parameters
       * @param {boolean} parameters.expandApp - Doplni objekty aplikacii
     */
  meAppSpaces(parameters = {}) {
    let path = '/me/appspaces';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['expandApp'] = false;

    if (typeof parameters['expandApp'] !== 'undefined') {
      queryParameters['expandApp'] = parameters['expandApp'];
    }

    queryParameters['expandApp'] = this.transformParameter(
      queryParameters['expandApp'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * User role in appspace
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appId - App ID
   */
  meAppSpacesForApp(appId, parameters = {}) {
    let path = '/me/appspaces/{appId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * User role in ppo
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appId - App ID
   * @param {string} appSpace - App space (PPO _id)
   */
  meAppInfoForAppSpace(appId, appSpace, parameters = {}) {
    let path = '/me/appspaces/{appId}/appspace/{appSpace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of user's access tokens
   * @method
   * @param {object} parameters - method options and parameters
   */
  meAccessTokens(parameters = {}) {
    let path = '/me/access-tokens';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Change user password
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  mePasswd(parameters = {}) {
    let path = '/me/passwd';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {file} parameters.upload - File to be uploaded
   */
  mePhotoUpload(parameters = {}) {
    let path = '/me/photo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'multipart/form-data');

    if (typeof parameters['upload'] !== 'undefined') {
      form['upload'] = parameters['upload'];
    }

    if (typeof parameters['upload'] === 'undefined') {
      throw Error('Missing required parameter: upload');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre vymazanie fotky uzivatela
   * @method
   * @param {object} parameters - method options and parameters
   */
  mePhotoDelete(parameters = {}) {
    let path = '/me/photo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  meDelete(parameters = {}) {
    let path = '/me/delete';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
     * Spracuje dáta poslané z kontaktného formulára z public časti stránky sportnet.online.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.data - 
     */
  webContactFormRequest(parameters = {}) {
    let path = '/request/web-contact-form';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} downloadId -
   */
  getDownloaderFile(downloadId, parameters = {}) {
    let path = '/downloader/{download_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Accept', 'application/pdf');
    headers.append('Accept', 'image/jpeg');
    headers.append('Accept', 'image/png');
    headers.append('Accept', 'image/gif');
    headers.append('Accept', 'text/plain');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{download_id}', downloadId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get Public User Profile
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   */
  getPublicUserProfile(userId, parameters = {}) {
    let path = '/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Can be used as image src
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   */
  getPublicUserImage(userId, parameters = {}) {
    let path = '/user-photo/{userId}.jpg';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Accept', 'image/png');
    headers.append('Accept', 'image/jpeg');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Can be used as image src
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} token - Photo token
   */
  getUserImage(userId, token, parameters = {}) {
    let path = '/users/{userId}/photo/{token}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Accept', 'image/png');
    headers.append('Accept', 'image/jpeg');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{token}', token);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} invitationId - Invitation Id
   */
  processInvitationResponseToGroups(invitationId, parameters = {}) {
    let path = '/crm-group-invitation/{invitationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{invitationId}', invitationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
}

export default new SportnetApi();
export { SportnetApi };
